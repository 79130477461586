<template>
  <div>
<!--    <CRow class="w-100  mb-5">-->

<!--      <CCol md="6">-->
<!--        <div class="w-100">-->
<!--          <div class="clearfix">-->
<!--            <img src="@/assets/logo.png" height="150"/>-->
<!--            <h3 class="pt-3 pl-2 font-italic">originalised you masterpieces</h3>-->
<!--          </div>-->
<!--          <div class="text-left">-->
<!--            <CButton-->
<!--                class="mr-2 mt-2 w-25 text-white"-->
<!--                color="success"-->
<!--            >Collector-->
<!--            </CButton>-->
<!--            <CButton-->
<!--                class="mr-2 mt-2 w-25"-->
<!--                color="secondary"-->
<!--            >Artist-->
<!--            </CButton>-->
<!--          </div>-->
<!--        </div>-->
<!--      </CCol>-->
<!--    </CRow>-->
    <CRow>
      <CCol col="12">
        <h4>All Categories</h4>


      </CCol>
      <CCol col="12">
        <CRow>
          <CCol>
            <CButton
                v-for="(item, index) in categories"
                v-bind:key="index"
                class="mr-2 mt-2"
                color="success"
                variant="outline"
                shape="pill"
                :pressed="categorie == item._id"
                @click="onSelectCategorie(item)"
            >{{ item.title }}
            </CButton>

          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <CRow class="mt-3">
      <CCol sm="6" md="4" lg="4" xl="3" v-for="(item, index) in itemMaket" v-bind:key="index">
        <ItemMarkets :item="item" @update:value="onLike" @infomation:value="onInfomation"/>
      </CCol>
    </CRow>


  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Sharing from "@/project/views/util/sharing";
import ItemMarkets from "@/project/views/util/ItemMarkets";

export default {

  name: 'Banks',
  components: { ItemMarkets, Sharing},
  data: function () {
    return {
      categorie : "",
      item: {
        title: 'This is a title',
        descrition: 'This is a description',
        coverImage: 'https://code.luasoftware.com/img/logo-badge.png'
      }
    }
  },


  // head: {
  //   title: {
  //     inner: 'It will be a pleasure'
  //   },
  //   // Meta tags
  //   meta: [
  //     {name: 'application-name', content: 'Name of my application'},
  //     {name: 'description', content: 'A description of the page', id: 'desc'}, // id to replace intead of create element
  //     // ...
  //     // Twitter
  //     {name: 'twitter:title', content: 'Content Title'},
  //     // with shorthand
  //     {n: 'twitter:description', c: 'Content description less than 200 characters'},
  //     // ...
  //     // Google+ / Schema.org
  //     {itemprop: 'name', content: 'Content Title'},
  //     {itemprop: 'description', content: 'Content Title'},
  //     // ...
  //     // Facebook / Open Graph
  //     {property: 'fb:app_id', content: '123456789'},
  //     {property: 'og:title', content: 'Content Title'},
  //     // with shorthand
  //     {p: 'og:image', c: 'https://lh3.googleusercontent.com/D5_lE1dlmuWNQqpy6vgPa0TI6Dy5ioX-HS-YvgQia9SxMeh_aS3d5K6mnLjFKfDzJMH9iFqpjVchggBouzFn1ebLj8nzEtG5RKWa=w1400-k'},
  //     // ...
  //   ]
  // },

  mounted() {
    this.$store.dispatch('metamask/onMetaMaskAddress')
    // this.$route.meta.title = "sso"
    // console.log(this.$route.meta)
  },

  created() {
    this.$store.dispatch("gateway/onCategorys")

    var obj = {};
    obj.categorie = "60e1af7f8e5715d5e9c1c047";
    this.$store.dispatch("gateway/onMarketsByCategorie", obj)
  },

  beforeDestroy() {

  },

  methods: {

    onInfomation(value){
      var obj = {};
      obj.id = value._id;
      this.$store.dispatch("gateway/onCollectionByNfts", obj)
    },

    onView() {
      this.$router.push('/home/market')
    },


    onLike(value) {
      console.log(value)
    },

    onSelectCategorie(item){
      this.categorie = item._id;

      var obj = {};
      obj.categorie = item._id;

      this.$store.dispatch('gateway/onMarketsByCategorie',obj);

    }

  },

  computed: {
    ...mapGetters({
      categories: 'gateway/objCategorys',
      itemMaket: 'gateway/objMarkets',
      nfts:'gateway/objCollectionId'
    }),
    pageUrl() {
      const domain = this.$store.state.site.domain
      return `https://${domain}/${this.$route.path}`
    }
  },

  watch: {
    categories(value){
      this.categorie = value[0]._id;
    },

    nfts(value){
      console.log(value)

      this.$router.push({name:'Assets',  query: {id:value.collectionId,assets:value.nftsId} })
    }
  }
}
</script>
